import {
  BookingVehicleInfoCardProps,
  ChosenOptions,
  VehicleSpecsCardProps,
} from "./BookingVehicleInfoCard.props";
import React, { useEffect, useState } from "react";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import { differenceInDays, differenceInHours, format } from "date-fns";
import { useTranslation } from "react-i18next";
import AppButton from "../../UI/AppButtons";
import { Button, Grid, Typography } from "@mui/material";
import { t } from "i18next";
import { VehicleSpecification } from "../VehicleSpecification/VehicleSpecification";
import { formatTheme } from "../../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import { TextResource } from "../../../@types/DTO/AppSetting";

function VehicleCardPitView({
  status = "Unavailable",
  vehicleInternationalCode,
  ...VehicleDetails
}: BookingVehicleInfoCardProps) {
  const [userId] = useState(localStorage.getItem("userID"));
  const ReservationDetails = BookingGetters.getBookingDetails();
  const [selectedImage] = useState<number>(0);
  const { t: translate } = useTranslation();
  const [startDate] = useState(
    new Date(ReservationDetails.pickUpDateAndTime || Date.now())
  );

  const [endDate] = useState(
    new Date(ReservationDetails.dropOffDateAndTime || Date.now())
  );
  const [discount, setDiscount] = useState(
    (100 - VehicleDetails.discount) / 100
  );

  const [differenceInHour] = useState(differenceInHours(endDate, startDate));
  const approximateDays = Math.ceil(differenceInHour / 24);
  const [pricePerDay] = useState(
    parseFloat(VehicleDetails.priceDetails.payNow.toString()) /
      (approximateDays == 0 ? 1 : approximateDays)
  );
  const [actualPrice] = useState(
    VehicleDetails.reservationSourcePayLater == "true"
      ? parseFloat(VehicleDetails?.actualPrice.toString()) /
          (approximateDays == 0 ? 1 : approximateDays)
      : (parseFloat(VehicleDetails?.actualPrice.toString()) * discount) /
          (approximateDays == 0 ? 1 : approximateDays)
  );
  const [totalPrice, setTotalPrice] = useState<number>(
    VehicleDetails.reservationSourcePayLater == "true"
      ? parseFloat(VehicleDetails.priceDetails.payNow)
      : parseFloat(VehicleDetails.priceDetails.payNow) * discount
  );
  const [totalPricePerDay, setTotalPricePerDay] = useState(pricePerDay);
  // const [discountedPricePerDay, setDiscountedPricePerDay] =
  //   useState(pricePerDay);
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  const [showInfo, setShowInfo] = useState(false);
  const toogleVehicleInfoCardShowingStatus = () => {
    setShowInfo(!showInfo);
  };
  const [closeInfo, setCloseInfo] = useState<String>("");
  const [vehicleCancelMsgLabel, setVehicleCancelMsgLabel] =
    useState<String>("");
  const textResourceInfo = BookingGetters.getTextResource()?.data ?? [];
  const [showReservationCancelMessage, setShowReservationCancelMessage] =
    useState<boolean>(false);
  useEffect(() => {
    const data: TextResource[] = textResourceInfo ?? [];
    const vehicleCancellationMsg = data.find(
      (item) => item.key === "vehicleCancelMessage"
    );
    setVehicleCancelMsgLabel(
      vehicleCancellationMsg?.body ||
        translate(
          "Noleggla senza pensierl! Hai tempo fino al > data < percancellare la prenotazione senza costi aggiuntivi !"
        )
    );
    const closeInfoData = data.find((item) => item.key === "closeInfoLabel");
    setCloseInfo(closeInfoData?.body || translate("Close"));
  }, [textResourceInfo]);
  const showVehicleCancellationMsg = () => {
    setShowReservationCancelMessage(!showReservationCancelMessage);
  };
  useEffect(() => {
    setDiscount((100 - VehicleDetails.discount) / 100);
  }, [VehicleDetails.discount]);
  const textResource = BookingGetters.getTextResource();
  const [YoungDriverFeeLabel, setYoungDriverFeeLabel] = useState<String>("");
  const isSmallScreen = window.innerWidth <= 768;
  const selectVehicleLabel = textResourceInfo.some(
    (item) => item.key === "SelectVehicleLabel"
  )
    ? textResourceInfo.find((item) => item.key === "SelectVehicleLabel")?.body
    : "";
  const vehicleInfoLabel = textResourceInfo.some(
    (item) => item.key === "informationLabel"
  )
    ? textResourceInfo.find((item) => item.key === "informationLabel")?.body
    : "";
  useEffect(() => {
    const data: TextResource[] = textResource?.data ?? [];
    const YoungDriverTextData = data.find(
      (item) => item.key === "VehicleYoungDriverFeeLabel"
    );
    setYoungDriverFeeLabel(
      YoungDriverTextData?.body
        .replace("extras_amount", `${VehicleDetails?.addedYoungDriverFee}`)
        .replace("currency", defaultCurrency || "€") || ""
    );
  }, [[textResource]]);

  useEffect(() => {
    setTotalPrice(
      VehicleDetails.reservationSourcePayLater == "true"
        ? parseFloat(VehicleDetails.priceDetails.payNow)
        : parseFloat(VehicleDetails.priceDetails.payNow) * discount
    );
    setTotalPricePerDay(
      VehicleDetails.reservationSourcePayLater == "true"
        ? parseFloat(VehicleDetails.priceDetails.payNow) /
            (approximateDays == 0 ? 1 : approximateDays)
        : parseFloat(
            (
              parseFloat(VehicleDetails.priceDetails.payNow) * discount
            ).toString()
          ) / (approximateDays == 0 ? 1 : approximateDays)
    );
  }, [VehicleDetails.priceDetails]);

  const scrollToTop = () => {
    window.parent.postMessage(
      {
        event_id: "scrollToTop",
      },
      "*"
    );
  };
  const payNowDisAmount =
    VehicleDetails.reservationSourcePayLater == "true"
      ? 0
      : (parseFloat(VehicleDetails.priceDetails.payLater.toString()) *
          VehicleDetails.discount) /
        100;

  const [paymentType] = useState<"PAYNOW" | "PAYLATER" | "BOOK NOW" | "">();
  const [selectedFields] = useState<ChosenOptions>({
    insurance: undefined,
    options: {},
  });
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const textColorOfSelectedDriver = dynamicTheme?.palette["primary"]?.main;
  const textColorReservationCancel =
    dynamicTheme?.palette["backgroundColor"]?.main;

  function BookButton() {
    return (
      <AppButton
        disabled={status.toLowerCase() === "unavailable"}
        color="primary"
        className="w-[6rem] h-[3rem] rounded-md"
        style={{ fontFamily: fontFamilyStyle ? fontFamilyStyle : undefined }}
        clickHandler={() => {
          window.scrollTo(0, 0);
          scrollToTop();
          VehicleDetails.moveToPayment({
            isPayLaterFee: false,
            extrasIncluded: false,
            extrasPrice: 0,
            payLaterFeeAmt: "",
            isDiscounted: payNowDisAmount,
            type: paymentType as "PAYNOW" | "PAYLATER",
            price: totalPrice,
            addedExtraOptions: selectedFields.options,
            selectedInsurance: selectedFields.insurance,
          });
        }}
      >
        <p
          className={`min-w-24 w-full h-full flex items-center justify-center text-${VehicleDetails.textColor} text-x capitalize font-normal`}
          style={{
            textTransform: "none",
          }}
        >
          {selectVehicleLabel ? (
            <div
              dangerouslySetInnerHTML={{
                __html: selectVehicleLabel,
              }}
            />
          ) : (
            <span>{t("Select")}</span>
          )}
        </p>
      </AppButton>
    );
  }
  const defaultCurrency = appSettings?.defaultCurrency;
  const isIpadMiniScreen = window.innerWidth >= 500 && window.innerWidth <= 780;
  const isDesktopScreen = window.innerWidth >= 1310;
  const vehicleCardHeightDesktop = appSettings?.vehicleCardParamHeightDesktop;
  const vehicleCardHeightMobile = appSettings?.vehicleCardParamHeightMobile;
  const groupFromTextResource =
    vehicleInternationalCode &&
    textResourceInfo.some((item) => item.key === vehicleInternationalCode)
      ? textResourceInfo.find((item) => item.key === vehicleInternationalCode)
          ?.body
      : "";
  return (
    <section
      className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] relative flex flex-col bg-white  box-border font-roboto  text-[#004188] z-12"
      style={{
        backgroundColor: VehicleDetails.backgroundColor,
        borderTopRightRadius: appSettings?.vehicleCardRoundEdge
          ? "32px"
          : "0px",
        borderBottomRightRadius: appSettings?.vehicleCardRoundEdge
          ? "32px"
          : "12px",
        borderBottomLeftRadius: appSettings?.vehicleCardRoundEdge
          ? "32px"
          : "12px",
      }}
    >
      <header
        className={
          "text-black w-full  flex flex-col p-2 px-4 bg-[#ffffff] rounded-tl-[32px] rounded-br-[32px] "
        }
        style={{
          borderTopRightRadius: appSettings?.vehicleCardRoundEdge
            ? "32px"
            : "0px",
          borderBottomLeftRadius: appSettings?.vehicleCardRoundEdge
            ? "32px"
            : "0px",
          borderWidth:
            process.env.REACT_APP_COMPANY_CODE !== "updrive" &&
            process.env.REACT_APP_COMPANY_CODE !== "felirent"
              ? "2px"
              : "0px",
        }}
      >
        <aside>
          <div
            className={"flex flex-row"}
            style={{
              height: !isSmallScreen
                ? `${vehicleCardHeightDesktop || 226}px`
                : `${vehicleCardHeightMobile || 226}px`,
              justifyContent: "space-between",
            }}
          >
            <div
              className="h-full p-2"
              style={{ width: isSmallScreen ? "70%" : "68%" }}
            >
              <div>
                {groupFromTextResource ? (
                  <div
                    style={{ paddingBottom: "24px" }}
                    dangerouslySetInnerHTML={{
                      __html: groupFromTextResource,
                    }}
                  />
                ) : (
                  <>
                    <div
                      className="box-border capitalize"
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "small",
                      }}
                    >
                      <span style={{ fontFamily: fontFamilyStyle }}>
                        {"Gruppo"} {VehicleDetails?.vehicleGroup}{" "}
                      </span>
                    </div>
                    <div
                      className="box-border capitalize font-bold"
                      style={{ textAlign: "left" }}
                    >
                      <span
                        style={{
                          fontFamily: fontFamilyStyle,
                          textTransform: "none",
                        }}
                      >
                        {VehicleDetails?.vehicleName}
                      </span>
                    </div>
                    <div
                      className="box-border text-sm capitalize"
                      style={{
                        textAlign: "left",
                        fontWeight: "normal",
                        fontSize: "x-small",
                      }}
                    >
                      <span style={{ fontFamily: fontFamilyStyle }}>
                        {VehicleDetails?.vehicleName
                          ?.split(" or ")[1]
                          ?.toLowerCase()}{" "}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div
                style={{
                  marginTop: "8px",
                  marginBottom: "8px",
                  paddingTop:
                    process.env.REACT_APP_COMPANY_CODE === "noleggiago" ||
                    process.env.REACT_APP_COMPANY_CODE === "updrive"
                      ? "15px"
                      : "",
                }}
              >
                {VehicleDetails?.specifications &&
                VehicleDetails.specifications.length > 0 ? (
                  VehicleDetails.specifications.map((el, index) => (
                    <div
                      key={index}
                      style={{ flexBasis: "calc(33.33% - 8px)" }}
                    >
                      <VehicleSpecsCard
                        {...el}
                        fontFamilyStyle={fontFamilyStyle}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      flexBasis: "calc(33.33% - 8px)",
                      visibility: "hidden",
                    }}
                  ></div>
                )}
              </div>
            </div>
            <div className="w-full h-full p-2">
              <div style={{ float: "right" }}>
                <Button onClick={toogleVehicleInfoCardShowingStatus}>
                  <p
                    className="text-md capitalize"
                    style={{
                      color: VehicleDetails.backgroundTextColor,
                      fontFamily: fontFamilyStyle,
                    }}
                  >
                    {vehicleInfoLabel ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: vehicleInfoLabel,
                        }}
                      />
                    ) : (
                      <span>{t("Info")}</span>
                    )}
                  </p>
                </Button>
              </div>
              <div
                style={{
                  width: isIpadMiniScreen ? "140%" : "",
                  float: "right",
                }}
              >
                {selectedImage >= 0 && (
                  <img
                    src={VehicleDetails.vehicleImages[selectedImage]}
                    className="w-50 h-48 object-contain" // Set fixed height
                    alt={""}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={"flex flex-row"}
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="box-border text-sm capitalize"
              style={{ textAlign: "left" }}
            >
              {/*{(() => {*/}
              {/*  const amountDetails = formatPriceWithCurrencySymbol(*/}
              {/*    BookingGetters.getCurrency(),*/}
              {/*    totalPricePerDay*/}
              {/*  );*/}
              {/*  return (*/}
              {/*    <span className="main-price text-2xl text-md">*/}
              {/*      <p*/}
              {/*        className={"whitespace-nowrap text-black"}*/}
              {/*        style={{ fontFamily: fontFamilyStyle }}*/}
              {/*      >*/}
              {/*        {(defaultCurrency || amountDetails.symbol) +*/}
              {/*          " " +*/}
              {/*          amountDetails.price}*/}
              {/*        <sub className="font-normal h-full">*/}
              {/*          {" "}*/}
              {/*          {translate("/day")}*/}
              {/*        </sub>*/}
              {/*      </p>*/}
              {/*    </span>*/}
              {/*  );*/}
              {/*})()}*/}
              {(() => {
                const amountDetails = formatPriceWithCurrencySymbol(
                  BookingGetters.getCurrency(),
                  actualPrice
                );
                const discountedAmountDetails = formatPriceWithCurrencySymbol(
                  BookingGetters.getCurrency(),
                  totalPricePerDay
                );

                return (
                  <>
                    <span className="main-price text-2xl text-md">
                      {/* the below styling is done as for 3 cards in a row the discount price and actual price should come in different line */}
                      <div
                        style={{
                          display:
                            !VehicleDetails.isDiscountApplied ||
                            !isDesktopScreen
                              ? "inline-flex"
                              : undefined,
                          alignItems: "center",
                        }}
                      >
                        {VehicleDetails.isDiscountApplied && (
                          <p
                            className="original-price text-gray-500 line-through"
                            style={{
                              fontFamily: fontFamilyStyle,
                              marginRight: isSmallScreen ? "0px" : "8px",
                              color: dynamicTheme?.palette["primary"]?.main,
                              fontSize: isSmallScreen ? "0.8em" : "0.9em",
                              fontWeight: "700",
                              width: isSmallScreen ? "39%" : "",
                              paddingLeft: isDesktopScreen
                                ? "7.5px"
                                : undefined,
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "700",
                                fontSize:
                                  isSmallScreen &&
                                  amountDetails.price.length > 7
                                    ? ".79em"
                                    : undefined,
                              }}
                            >
                              {(defaultCurrency || amountDetails.symbol) +
                                " " +
                                amountDetails.price}
                            </span>
                            <span
                              className="font-normal h-full"
                              style={{
                                fontWeight: "400",
                                textTransform: "none",
                              }}
                            >
                              {translate("/day")}
                            </span>
                          </p>
                        )}
                        <p
                          className="discounted-price whitespace-nowrap text-black"
                          style={{
                            fontFamily: fontFamilyStyle,
                            paddingLeft:
                              !VehicleDetails.isDiscountApplied ||
                              isDesktopScreen
                                ? "7.5px"
                                : undefined,
                            width:
                              VehicleDetails.isDiscountApplied && isSmallScreen
                                ? "62%"
                                : "",
                          }}
                        >
                          <span
                            style={{
                              fontSize:
                                VehicleDetails.isDiscountApplied &&
                                isSmallScreen
                                  ? "1em"
                                  : "1.1em",
                              fontWeight: "700",
                            }}
                          >
                            {(defaultCurrency ||
                              discountedAmountDetails.symbol) +
                              " " +
                              discountedAmountDetails.price}
                          </span>
                          <span
                            className="font-normal h-full"
                            style={{
                              fontSize:
                                VehicleDetails.isDiscountApplied &&
                                isSmallScreen
                                  ? ".8rem"
                                  : "1rem",
                              fontWeight: "400",
                              textTransform: "none",
                            }}
                          >
                            {translate("/day")}
                          </span>
                        </p>
                      </div>
                    </span>
                    {isSmallScreen && <br />}
                  </>
                );
              })()}

              {(() => {
                const amountDetails = formatPriceWithCurrencySymbol(
                  BookingGetters.getCurrency(),
                  totalPrice
                );
                return (
                  <span
                    className="total-price text-[#707070] whitespace-nowrap font-normal"
                    style={{
                      fontSize: "x-small",
                      fontFamily: fontFamilyStyle,
                      paddingLeft: "7.5px",
                    }}
                  >
                    <span
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {VehicleDetails.isDiscountApplied && (
                        <p
                          className="original-price text-gray-500 line-through"
                          style={{
                            fontFamily: fontFamilyStyle,
                            marginRight: "8px",
                            color: dynamicTheme?.palette["primary"]?.main,
                          }}
                        >
                          {(defaultCurrency || amountDetails.symbol) +
                            (VehicleDetails.reservationSourcePayLater == "true"
                              ? parseFloat(
                                  VehicleDetails.actualPrice.toString()
                                )
                              : parseFloat(
                                  VehicleDetails.actualPrice.toString()
                                ) * discount
                            ).toFixed(2)}
                        </p>
                      )}
                      {defaultCurrency || amountDetails.symbol + " "}{" "}
                    </span>
                    <span>{amountDetails.price}</span>
                    <span>totale per periodo</span>
                    {appSettings?.vehicleReservationCancelMessage && (
                      <p
                        className="text-md font-bold px-1 relative"
                        style={{
                          color: textColorOfSelectedDriver,
                        }}
                      >
                        <b>Cancella gratuitamente</b>
                        <span
                          onClick={showVehicleCancellationMsg}
                          className="rounded-full p-[.15rem] cursor-pointer"
                          style={{
                            lineHeight: "24px",
                            backgroundColor: textColorOfSelectedDriver,
                            marginLeft: "3px",
                            paddingRight: "5px",
                            color: textColorReservationCancel,
                          }}
                        >
                          {" ?"}
                        </span>
                        {showReservationCancelMessage && (
                          <div
                            className="absolute left-[118%] transform -translate-x-1/2 -top-[6.75rem] w-auto max-w-xs p-3 rounded-md z-10 "
                            style={{
                              width: "150%",
                              height: "auto",
                              backgroundColor: textColorOfSelectedDriver,
                            }}
                          >
                            <p
                              className="break-words whitespace-normal"
                              style={{
                                color: textColorReservationCancel,
                              }}
                            >
                              {vehicleCancelMsgLabel}
                            </p>
                          </div>
                        )}
                      </p>
                    )}
                  </span>
                );
              })()}
            </div>
            <div style={{ float: "right" }}>
              <p
                className="text-[primary] text-md capitalize font-bold"
                style={{
                  paddingTop:
                    isDesktopScreen && VehicleDetails.isDiscountApplied
                      ? "21px"
                      : undefined,
                }}
              >
                <BookButton />
              </p>
            </div>
          </div>
          <div className="flex flex-row mt-2" style={{ textAlign: "left" }}>
            {appSettings?.showOnrequestVehicles &&
            VehicleDetails?.vehicleStatus?.toLowerCase() == "onrequest" ? (
              <div
                className="box-border text-[13px] text-gray-600 capitalize font-bold"
                style={{
                  color:
                    process.env.REACT_APP_COMPANY_CODE === "felirent"
                      ? "red"
                      : "rgba(0, 0, 0, 0.6)",
                  textAlign: "left",
                  fontFamily: fontFamilyStyle,
                }}
              >
                {t(
                  "Vehicle with limited availability - subjected to confirmation"
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-row mt-2" style={{ textAlign: "left" }}>
            <Grid container spacing={0}>
              {VehicleDetails?.addedOOHFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: textColorOfSelectedDriver,
                    }}
                  >
                    Include {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedOOHFee} {translate("of OOH Fee")}
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedOnewayFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: textColorOfSelectedDriver,
                    }}
                  >
                    Include {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedOnewayFee?.toFixed(2)}{" "}
                    {translate("of oneway Fee")}
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedOnewayOOHFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: textColorOfSelectedDriver,
                    }}
                  >
                    Include {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedOnewayOOHFee}{" "}
                    {translate("of oneway and OOH Fee")}
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedYoungDriverFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: textColorOfSelectedDriver,
                    }}
                  >
                    {YoungDriverFeeLabel ||
                      "Include" +
                        " " +
                        (defaultCurrency || "€") +
                        " " +
                        VehicleDetails?.addedYoungDriverFee +
                        " " +
                        translate("of Y_D fee")}
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedYoungDriver2Fee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: textColorOfSelectedDriver,
                    }}
                  >
                    Include {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedYoungDriver2Fee}{" "}
                    {translate("of Y_D_2 fee")}
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedSeniorDriverFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: textColorOfSelectedDriver,
                    }}
                  >
                    Include {defaultCurrency || "€"}
                    {"  "}
                    {VehicleDetails?.addedSeniorDriverFee}{" "}
                    {translate("of S_D fee")}
                  </Typography>
                </Grid>
              )}
              {VehicleDetails?.addedNoAgeFee && (
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "small",
                      textAlign: "left",
                      color: textColorOfSelectedDriver,
                    }}
                  >
                    Include {defaultCurrency || "€"}
                    {VehicleDetails?.addedNoAgeFee} {translate("of No age fee")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>
        </aside>
      </header>

      {showInfo && appSettings?.vehicleInfoChoice == "baseExcessAndDepositVal" && (
        <div
          style={{ overflowY: "auto" }}
          className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] h-full w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn"
        >
          <div>
            <Button
              className="w-1/3 flex justify-end"
              style={{ color: VehicleDetails.backgroundTextColor }}
              onClick={() => {
                toogleVehicleInfoCardShowingStatus();
              }}
            >
              {translate("Close")}
            </Button>
            <div className="h-fit flex flex-row flex-wrap w-fit gap-12 ml-4">
              {/*<span className="h-fit flex items-left text-black md:text-md text-md">*/}
              {/*  {"Base Excess Value"}*/}
              {/*</span>*/}
            </div>
            <div className="h-fit items-left flex-wrap w-fit gap-12 ml-4 my-2">
              <span className="items-left font-light text-black md:text-md text-md">
                <p className="my-2 flex items-left">
                  {translate("theft.excess.amount")}{" "}
                  <span className="mx-4" style={{ fontWeight: "bold" }}>
                    {defaultCurrency || "€"}
                    {VehicleDetails?.theftExcessAmount}
                  </span>
                </p>
                <p className="my-2 flex items-left">
                  {translate("damage.excess.amount")}
                  <span className="mx-4" style={{ fontWeight: "bold" }}>
                    {defaultCurrency || "€"}
                    {VehicleDetails?.damageExcessAmount}
                  </span>
                </p>
                <p className="flex items-left">
                  {translate("deposit.excess.amount")}
                  <span className="mx-4" style={{ fontWeight: "bold" }}>
                    {defaultCurrency || "€"} {VehicleDetails?.DepositAmount}
                  </span>
                </p>
              </span>
            </div>
          </div>
        </div>
      )}
      {showInfo && appSettings?.vehicleInfoChoice == "includedOptional" && (
        <div
          style={{ overflowY: "auto" }}
          className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] h-full w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn"
        >
          <div>
            <Button
              className="w-1/3 flex justify-end"
              style={{ color: VehicleDetails.backgroundTextColor }}
              onClick={() => {
                toogleVehicleInfoCardShowingStatus();
              }}
            >
              <span style={{ fontFamily: fontFamilyStyle }}>{closeInfo}</span>
            </Button>
            <div className="h-fit flex flex-row flex-wrap w-fit gap-12 ml-4">
              {(() => {
                const extras = VehicleDetails.extras;
                const included: string[] = [];
                const notIncluded: string[] = [];

                extras.forEach((el) => {
                  if (
                    el.Charge.IncludedInRate &&
                    el.Charge.Description != null
                  ) {
                    included.push(el.Charge.Description);
                  } else if (el.Charge.Description != null) {
                    notIncluded.push(el.Charge.Description);
                  }
                });

                return (
                  <>
                    <span className="font-light text-gray-600 text-sm text-left">
                      <h2
                        className="text-md font-bold"
                        style={{ color: VehicleDetails.backgroundTextColor }}
                      >
                        {included.length > 0 && (
                          <span style={{ fontFamily: fontFamilyStyle }}>
                            {translate("Including")}
                          </span>
                        )}
                      </h2>
                      <br></br>
                      {included.map((val, index) => (
                        <div key={index}>
                          <h3 style={{ fontFamily: fontFamilyStyle }}>{val}</h3>
                        </div>
                      ))}
                    </span>
                    <span className="font-light text-gray-600 text-sm text-left">
                      <h2
                        className="text-md text-[#17A1B7] font-bold"
                        style={{ color: VehicleDetails.backgroundTextColor }}
                      >
                        {notIncluded.length > 0 && (
                          <span style={{ fontFamily: fontFamilyStyle }}>
                            {translate("Not Including")}
                          </span>
                        )}
                      </h2>
                      <br></br>
                      {notIncluded.map((val, index) => (
                        <div
                          key={index}
                          style={{ fontFamily: fontFamilyStyle }}
                        >
                          {val}
                        </div>
                      ))}
                    </span>
                  </>
                );
              })()}
            </div>
          </div>
        </div>
      )}
      {showInfo && appSettings?.vehicleInfoChoice == "vehicleParameters" && (
        <div
          style={{ overflowY: "auto" }}
          className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] h-full w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn"
        >
          <div>
            <Button
              className="w-1/3 flex justify-end"
              style={{ color: VehicleDetails.backgroundTextColor }}
              onClick={() => {
                toogleVehicleInfoCardShowingStatus();
              }}
            >
              <span style={{ fontFamily: fontFamilyStyle }}>{closeInfo}</span>
            </Button>
          </div>
          {VehicleDetails.specifications.map((el, index) => {
            return (
              <div
                key={index}
                style={{ flexBasis: "calc(33.33% - 8px)" }} // Adjust the flex basis to create a 3-column layout
              >
                <VehicleSpecification {...el} />
              </div>
            );
          })}
        </div>
      )}
    </section>
  );
}

function VehicleSpecsCard({
  iconUrl,
  name,
  fontFamilyStyle,
}: VehicleSpecsCardProps) {
  return (
    <div className="w-full min-w-fit flex flex-row gap-2 items-center">
      {iconUrl && (
        <img
          src={iconUrl}
          alt={iconUrl}
          style={{
            height:
              process.env.REACT_APP_COMPANY_CODE === "updrive"
                ? "0.75rem"
                : "1.25rem",
            width:
              process.env.REACT_APP_COMPANY_CODE === "updrive"
                ? "0.75rem"
                : "1.25rem",
          }}
        />
      )}
      <span
        className="font-light text-black"
        style={{
          fontSize: "x-small",
          textAlign: "left",
          paddingTop:
            process.env.REACT_APP_COMPANY_CODE === "updrive" ? "0px" : "3px",
          flex: "1",
          marginTop:
            process.env.REACT_APP_COMPANY_CODE === "updrive" ? "5px" : "8px",
          fontFamily: fontFamilyStyle,
        }}
      >
        {name}
      </span>
    </div>
  );
}

export default VehicleCardPitView;
